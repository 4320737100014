.App .MuiDropzoneArea-root {
  background-color: rgba(197, 197, 197, 0.5);
}

#PhotoUploadSubmit {
  margin-top: 1em;
}

.App .MuiCard-root {
  transition: 1s;
  z-index: 1;
  position: relative;
}

.App .MuiCardMedia-img {
  transition: 1s;
  z-index: 1;
}