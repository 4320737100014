.App .MuiDropzoneArea-root {
  background-color: rgba(197, 197, 197, 0.5);
}

#PhotoUploadSubmit {
  margin-top: 1em;
}

.hljs {
  background: rgba(0,0,0,0);
}
